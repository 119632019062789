.customFileUpload {
    background-color: #FF6242; /* Button color */
    color: white; /* Text color */
    padding: 15px 30px; /* Adjust padding as needed */
    border-radius: 55px; /* Rounded corners */
    cursor: pointer; /* Show hand cursor on hover */
    display: flex; /* Make label inline */
}

.customFileUpload:hover {
    background-color: #FF6242; /* Darker color on hover */
}

.finishButton{
    background-color: #FF6242; /* Button color */
    color: white; /* Text color */
    padding: 15px 30px; /* Adjust padding as needed */
    border-radius: 55px; /* Rounded corners */
}

.InvitationClientWrapper {
    height: 100vh;
    display: grid;
    grid-template-rows: 90% 10%;
}

a{
    color: inherit;
    text-decoration: inherit;
    cursor: inherit;
}

.locationButton{

    -webkit-animation: fadein 4s; /* Safari, Chrome and Opera > 12.1 */
       -moz-animation: fadein 4s; /* Firefox < 16 */
        -ms-animation: fadein 4s; /* Internet Explorer */
         -o-animation: fadein 4s; /* Opera < 12.1 */
            animation: fadein 4s;
}

@keyframes fadein {
    from { opacity: 0; }
    to   { opacity: 1; }
}

/* Firefox < 16 */
@-moz-keyframes fadein {
    from { opacity: 0; }
    to   { opacity: 1; }
}

/* Safari, Chrome and Opera > 12.1 */
@-webkit-keyframes fadein {
    from { opacity: 0; }
    to   { opacity: 1; }
}

/* Internet Explorer */
@-ms-keyframes fadein {
    from { opacity: 0; }
    to   { opacity: 1; }
}

/* Opera < 12.1 */
@-o-keyframes fadein {
    from { opacity: 0; }
    to   { opacity: 1; }
}

.locationButton:hover{
    color: #fff;
    cursor: pointer;
}