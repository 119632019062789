.WrapperBox {
    background-color: #fff;
    // border-radius: 8px;
    // box-shadow: 0 1px 5px rgb(0 0 0 / 0.2);
    padding: 25px;
    width: 70%;

    .InputLabel {
        color: #4d4d4d;
        font-weight: 200;
        font-size: 13px;
        padding-left: 10px;
        letter-spacing: 1px;
    }

    .FormTitle {
        color: #C1272D;
        font-weight: 900;
        font-size: 34px;
    }

    .FormSubtitle {
        color: #000;
        font-size: 16px;
        letter-spacing: 1px;
    }
}

.LoginPageWrapper{
    height: 100vh;
    width: 100%;

    .LoginFormWrapper {
        width: 100%;
        height: 100%;

        display: flex;
        justify-content: center;
        align-items: center;

        .Form {
            width: 90%;
        }
    }
}