.PageWrapper {
    display: flex;
    flex-direction: column;
}

.HeaderWrapper{
    background-color: #dfdada;
    padding: 10px 20px;
    display: flex;
    justify-content: end;
}

.MainWrapper {
    display: flex;
    flex-direction: row;
    align-items: flex-start;
}

.MenuWrapper {
    width: 60px;
    position: fixed;
    top: 0;
    left: 0;
    bottom: 0;
    background-color: #f0f0f0;
    overflow-y: hidden;
    z-index: 99;
}

.ContentWrapper {
    flex-grow: 1;
    padding-left: 60px;
    overflow-y: auto;
}

.NavItem {
    padding: 15px;
    border-top: 1px solid #fff;
    border-bottom: 1px solid #fff;
    cursor: pointer;
    text-align: center;
}

.ActiveNavItem {
    background-color: #fff;
}

.LinkOnPopOver {
    padding: 0;
    margin: 5px 0;
}

.LinkOnPopOver:hover {
    cursor: pointer;
    border-radius: 2px;
    background-color: #F0F1F3;
}

.LinkOnPopOverActive {
    background-color: #F0F1F3;
    color: #001E38;
    border-radius: 2px;
    border-left: 3px solid #001E38;
}