* {
  font-family: "Inter", sans-serif;
}

::-webkit-scrollbar {
  width: 5px;
}

/* Track */
::-webkit-scrollbar-track {
  background: #f1f1f1; 
}
  
/* Handle */
::-webkit-scrollbar-thumb {
  background: #c2c0c0; 
}

/* Handle on hover */
::-webkit-scrollbar-thumb:hover {
  background: #8b8b8b; 
}

.ql-align-right {
  text-align: right !important;
}

.ql-align-center {
  text-align: center !important;
}
